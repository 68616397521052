.Deck {
  box-sizing: border-box;
  width: 368px;
  height: 176px;
  overflow: hidden;
  border: 3px solid #333;
  padding: 0;
  margin-top: 8px;
  margin-bottom: 8px;
}

.DeckRow {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-bottom: 3px solid #333;
  white-space: nowrap;
  height: 16px;
  font-size: 14px;
  letter-spacing: -2px;
  line-height: 16px;
}

.DeckRow.VizRow {
  height: 62px;
  padding: 0;
  overflow: hidden;
}

.DeckViz {
  position: absolute;
  width: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
}

.Waveform {
  position: absolute;
  top: 2px;
  left: 0;
}

.TrackSegment {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  display: inline-block;
  height: 60px;
}

.Cursor {
  position: absolute;
  height: 60px;
  width: 2px;
  z-index: 1;
  background-color: white;
}

.ScrollButton {
  display: block;
  margin: 0 auto;
  padding: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 0;
  border-bottom: 0;
}

.ScrollButton.Up {
  border-bottom: 15px solid white;
}

.ScrollButton.Down {
  border-top: 15px solid white;
}

.Hide {
  display: none;
}

.TrackSegment.Low {
  border-left: 1px solid #6966ab;
}

.TrackSegment.High {
  background-color: #ef476f60;
  border-left: 1px solid #ef476f;
}

.Row-B .Title,
.Row-B .Artist {
  position: absolute !important;
  right: 4px !important;
  direction: rtl;
  text-align: left;
}

.Viz-A {
  position: absolute;
  width: 100%;
  background-color: rgba(26, 156, 252, 0.5);
}

.Viz-B {
  position: absolute;
  width: 100%;
  background-color: rgba(222, 120, 31, 0.5);
}

.ScrollableList {
  position: relative;
  top: -65px;
  height: 118px;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.ListItem {
  scroll-snap-align: start;
  height: 120px;
  overflow: hidden;
}

.DeckRow .Title,
.DeckRow .Artist {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DeckRow .Tempo {
  position: relative;
}

.DeckRow .dbMeter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.Deck-A .DeckRow .dbMeter {
  background-color: #012c4b;
}

.Deck-B .DeckRow .dbMeter {
  background-color: #43240a;
}
