.HeaderTable {
  box-sizing: border-box;
  width: 368px;
  border: 3px solid #333;
  border-bottom: 0;
  padding: 0;
  margin-top: 2px;
  margin-bottom: 6px;
}

.HeaderTable .HeaderRow {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  border-bottom: 3px solid #333;
  white-space: nowrap;
}
