.ToggleButton {
  width: 53px;
  text-align: center;
}

.ToggleButton .OnOffLight {
  margin: 0 auto;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #666;
}

.ToggleButton .OnOffLight.On {
  background-color: greenyellow;
}

.ToggleButton .Text {
  margin-top: 4px;
  font-size: 14px;
  color: white;
}

.ToggleButton button {
  width: 40px;
  height: 40px;
  margin: 6px;
  border: 0;
  border-radius: 2px;
  background-color: #e6e6f0;
  color: black;
}

.ToggleButton button:active {
  background-image: linear-gradient(to bottom, #e4e4e4, #f7f7f7);
  border: 1px solid #afafaf;
}