.Sequencer button {
  width: 80px;
  height: 40px;
  margin: 6px;
  border: 0;
  border-radius: 2px;
  background-color: #e6e6f0;
  color: black;
}

.Sequencer button.Current, .Sequencer button:active {
  background: radial-gradient(hsl(240, 70%, 70%), hsl(240, 66%, 62%));
}
